import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Grommet, Box } from 'grommet';
import { Normalize } from 'styled-normalize';
import styled, { createGlobalStyle } from 'styled-components';
import { Helmet } from 'react-helmet';
import { CookiesProvider } from 'react-cookie';
import { SEOContext } from 'gatsby-plugin-wpgraphql-seo';
import Header from '../components/header';
import Footer from '../components/footer';
import theme from '../components/theme';
import { CompanyContext, HeaderPopupContext } from '../context';
import Banner from '../components/banner';
import Partner from '../components/partner';
import Steps from '../components/steps';
import { Trustpilot } from '../components/Trustpilot';
import MaxWidth from '../components/maxWidth';
import AdvertBanner from '../components/topAdvert';

export const GlobalStyle = createGlobalStyle`

 * {
    box-sizing: border-box;
 }

address {
    font-style: normal;
}

.page-heading.light-1 + .component-renderer > .light-1:first-child {

    padding-top: 0;
    .block{
    padding-top: 0;
}
}

.show-small {
    @media(min-width: 1201px) {
        display: none;
    }
}
.show-large {
    @media(max-width: 1200px) {
        display: none;
    }
}
html {
    scroll-behavior: smooth;
}
@media screen and (prefers-reduced-motion: reduce) {
	html {
		scroll-behavior: auto;
	}
}

 `;

const StyledMain = styled(Box)`
    z-index: 10;
`;

const StyledBg = styled.div``;
const Layout = ({ children, data }) => {
    const [open, setOpen] = useState(false);
    const onOpen = () => setOpen(true);
    const onClose = () => setOpen(false);
    const [openMobile, setOpenMobile] = useState(false);
    const onOpenMobile = () => setOpenMobile(true);
    const onCloseMobile = () => setOpenMobile(false);

    // const hideContactForm = !!data?.wpPage?.contentItems?.hideContactForm;

    const {
        wp: { companyInfo, siteBanner, seo },
    } = useStaticQuery(graphql`
        query SiteInfoQuery {
            wp {
                seo {
                    contentTypes {
                        post {
                            title
                            schemaType
                            metaRobotsNoindex
                            metaDesc
                        }
                        page {
                            metaDesc
                            metaRobotsNoindex
                            schemaType
                            title
                        }
                    }
                    webmaster {
                        googleVerify
                        yandexVerify
                        msVerify
                        baiduVerify
                    }
                    schema {
                        companyName
                        personName
                        companyOrPerson
                        wordpressSiteName
                        siteUrl
                        siteName
                        inLanguage
                        logo {
                            mediaItemUrl
                            altText
                        }
                    }
                    social {
                        facebook {
                            url
                            defaultImage {
                                mediaItemUrl
                            }
                        }
                        instagram {
                            url
                        }
                        linkedIn {
                            url
                        }
                        mySpace {
                            url
                        }
                        pinterest {
                            url
                            metaTag
                        }
                        twitter {
                            username
                        }
                        wikipedia {
                            url
                        }
                        youTube {
                            url
                        }
                    }
                }
                siteBanner {
                    buttonText
                    show
                    content
                    heading
                    buttonLinkUri
                    # buttonLink {
                    #     ... on WpPage {
                    #         id
                    #         uri
                    #         slug
                    #     }
                    #     ... on WpPost {
                    #         id
                    #         uri
                    #         slug
                    #     }
                    #     ... on WpClient {
                    #         id
                    #         uri
                    #         slug
                    #     }
                    #     ... on WpIntegration {
                    #         id
                    #         uri
                    #         slug
                    #     }
                    #     ... on WpSolution {
                    #         id
                    #         uri
                    #         slug
                    #     }
                    # }
                }
                companyInfo {
                    address
                    companyName
                    county
                    email
                    linkedin
                    phoneNumber
                    postcode
                    town
                    twitter
                    footerText
                }
            }
        }
    `);

    const schema = {
        '@context': 'http://schema.org',
        '@type': 'Organization',
        url: 'https://www.conservatorypanels.com"',
        name: companyInfo.companyName,
        address: {
            '@type': 'PostalAddress',
            addressLocality: 'UK',
            postalCode: companyInfo.postcode,
            streetAddress: `${companyInfo.address}, ${companyInfo.county}, ${companyInfo.town}`,
        },
        email: companyInfo.email,
        telephone: companyInfo.phoneNumber,
    };

    return (
        <>
            <SEOContext.Provider value={{ global: seo }}>
                <Helmet encodeSpecialCharacters={false}>
                    <script type="application/ld+json">{JSON.stringify(schema)}</script>

                    {/* <script type="text/javascript">
                        {`
                       var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
                       (function(){
                       var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
                       s1.async=true;
                       s1.src='https://embed.tawk.to/606f2edbf7ce182709386be9/1f2p4u28l';
                       s1.charset='UTF-8';
                       s1.setAttribute('crossorigin','*');
                       s0.parentNode.insertBefore(s1,s0);
                       })();
                        `}
                    </script> */}
                </Helmet>
                <Normalize />
                <GlobalStyle />
                <CookiesProvider>
                    <CompanyContext.Provider value={{ ...companyInfo }}>
                        <HeaderPopupContext.Provider
                            value={{
                                open,
                                onOpen,
                                onClose,
                                openMobile,
                                onOpenMobile,
                                onCloseMobile,
                            }}
                        >
                            <Grommet theme={theme}>
                                <StyledBg>
                                    {siteBanner.show && <Banner {...siteBanner} />}
                                    <Header companyInfo={companyInfo} />
                                    {/* <AdvertBanner /> */}
                                    <StyledMain as="main">{children}</StyledMain>

                                    <Steps />

                                    <Partner />
                                    {/* <Box pad={{ vertical: 'large' }} className="light-1">
                                        <MaxWidth>
                                            <Trustpilot />
                                        </MaxWidth>
                                    </Box> */}
                                    <Footer companyInfo={companyInfo} />
                                </StyledBg>
                            </Grommet>
                        </HeaderPopupContext.Provider>
                    </CompanyContext.Provider>
                </CookiesProvider>
            </SEOContext.Provider>
        </>
    );
};

export default Layout;
